var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("h1", { staticClass: "float-left" }, [
                _vm._v("Basic Information"),
              ]),
              _c(
                "v-btn",
                {
                  staticClass:
                    "blue--text text--lighten-2 font-weight-bold float-right",
                  attrs: { small: "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.updateDetail()
                    },
                  },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "9", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Name", type: "text" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.updateDetail()
                          },
                        },
                        model: {
                          value: _vm.currentLocation.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentLocation, "name", $$v)
                          },
                          expression: "currentLocation.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Contact Name", type: "text" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.updateDetail()
                          },
                        },
                        model: {
                          value: _vm.currentLocation.contactName,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentLocation, "contactName", $$v)
                          },
                          expression: "currentLocation.contactName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "9", sm: "6" } },
                    [
                      _c("detail-location-auto-complete", {
                        attrs: { address: _vm.fullAddress },
                        on: { event: _vm.setLocation },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3", sm: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Contact Phone", type: "text" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.updateDetail()
                          },
                        },
                        model: {
                          value: _vm.currentLocation.contactPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentLocation, "contactPhone", $$v)
                          },
                          expression: "currentLocation.contactPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  style: _vm.$vuetify.breakpoint.smAndUp
                    ? "border-right:1px solid #CCCCCC"
                    : "",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "v-card-title",
                    [
                      _c("h1", { staticClass: "float-left" }, [
                        _vm._v("Operational Information"),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "blue--text text--lighten-2 font-weight-bold float-right",
                          attrs: { small: "", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.updateDetail()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6", sm: "6" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##:##",
                                    expression: "'##:##'",
                                  },
                                ],
                                staticClass: "pt-0",
                                attrs: {
                                  rules: [_vm.rules.time],
                                  label: "Early Time",
                                  "prepend-icon": "mdi-clock-time-four-outline",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.updateDetail()
                                  },
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.updateDetail()
                                  },
                                },
                                model: {
                                  value: _vm.currentLocation.earlyTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentLocation,
                                      "earlyTime",
                                      $$v
                                    )
                                  },
                                  expression: "currentLocation.earlyTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6", sm: "6" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##:##",
                                    expression: "'##:##'",
                                  },
                                ],
                                staticClass: "pt-0",
                                attrs: {
                                  rules: [_vm.rules.time],
                                  label: "Late Time",
                                  "prepend-icon": "mdi-clock-time-four-outline",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.updateDetail()
                                  },
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.updateDetail()
                                  },
                                },
                                model: {
                                  value: _vm.currentLocation.lateTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentLocation,
                                      "lateTime",
                                      $$v
                                    )
                                  },
                                  expression: "currentLocation.lateTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-textarea", {
                                staticClass: "pt-0 pb-0",
                                attrs: {
                                  "auto-grow": "",
                                  label: "Notes",
                                  "row-height": "30",
                                  rows: "4",
                                },
                                nativeOn: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.updateDetail()
                                  },
                                },
                                model: {
                                  value: _vm.currentLocation.notes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentLocation, "notes", $$v)
                                  },
                                  expression: "currentLocation.notes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-card-title", [
                    _c("h1", { staticClass: "float-left" }, [
                      _vm._v("Attached Customers"),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "10", md: "9" },
                                    },
                                    [_c("customer-auto-complete")],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0 pl-0",
                                      attrs: { cols: "2", md: "3" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "while--text blue text--lighten-2 font-weight-bold float-left ml-2 mt-2",
                                          class: _vm.$vuetify.breakpoint
                                            .smAndDown
                                            ? "float-right"
                                            : "",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addToLocation()
                                            },
                                          },
                                        },
                                        [_vm._v(" Add ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0",
                              attrs: { cols: "12", md: "6" },
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-n2",
                                attrs: {
                                  "append-icon": "mdi-magnify",
                                  "hide-details": "",
                                  label: "Search",
                                  "single-line": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "px-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "px-1 pb-1",
                                  staticStyle: { "max-height": "335px" },
                                },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-1",
                                    attrs: {
                                      "footer-props": {
                                        "items-per-page-options": [5],
                                      },
                                      headers: _vm.headers,
                                      items:
                                        _vm.currentLocation.customerCompanies,
                                      "items-per-page": 5,
                                      search: _vm.search,
                                      "calculate-widths": "",
                                      "fixed-header": "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item.id",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "while--text red text--lighten-2 font-weight-bold",
                                                attrs: { small: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFromLocation(
                                                      item.id
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" Remove ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }