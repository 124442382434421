var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backend-customer-autocomplete" },
    [
      _c(
        "v-autocomplete",
        {
          attrs: {
            clearable: "",
            items: _vm.customerCompaniesList,
            loading: _vm.loading,
            "search-input": _vm.search,
            color: "info",
            "item-text": "name",
            "item-value": "id",
            label: _vm.customerText,
            "return-object": "",
            "close-on-content-click": "",
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.search = $event
            },
            "update:search-input": function ($event) {
              _vm.search = $event
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.fireEnterEvent()
            },
            change: function ($event) {
              return _vm.pushCustomerCompany()
            },
            "click:clear": function ($event) {
              return _vm.removeCustomer()
            },
          },
          model: {
            value: _vm.customer,
            callback: function ($$v) {
              _vm.customer = $$v
            },
            expression: "customer",
          },
        },
        [
          _c(
            "v-list-item",
            { attrs: { slot: "append-item" }, slot: "append-item" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.customerForm()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus")]), _vm._v(" Add Customer ")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }