var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        {
          staticClass: "pb-0 pt-0",
          staticStyle: { "max-width": "1600px" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "div",
                { staticClass: "header-banner blue lighten-1" },
                [
                  _c("v-row", [
                    _c("div", { staticClass: "col-md-5 d-flex align-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "white rounded-circle d-inline-block text-center short-name ml-md-10 ml-5 shortname-circal",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transition-swing font-weight-bold grey--text font-size-42",
                            },
                            [_vm._v(" " + _vm._s(_vm.shortLocationName) + " ")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "white--text d-inline-block align-middle ml-4",
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "mb-1 font-size-24" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.currentLocation.name) + " "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "font-size-18" }, [
                            _vm._v(
                              " " + _vm._s(_vm.currentLocation.address) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "font-size-18" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.currentLocation.city) +
                                ", " +
                                _vm._s(
                                  _vm.currentLocation.stateProvinceRegion
                                ) +
                                " " +
                                _vm._s(_vm.currentLocation.postalCode) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-7 pt-0 pb-0" },
                      [
                        _c(
                          "GmapMap",
                          {
                            ref: "mapRef",
                            style: _vm.$vuetify.breakpoint.mdAndUp
                              ? "height: 220px; border-radius: 0px 4px 4px 0px"
                              : "height: 220px; border-radius: 0px 0px 4px 0px",
                            attrs: {
                              id: "map",
                              center: {
                                lat: Number(_vm.currentLocation.latitude),
                                lng: Number(_vm.currentLocation.longitude),
                              },
                              zoom: 9,
                              "map-type-id": "terrain",
                            },
                          },
                          [
                            _c("GmapMarker", {
                              attrs: {
                                position: {
                                  lat: Number(_vm.currentLocation.latitude),
                                  lng: Number(_vm.currentLocation.longitude),
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { centered: "" },
                      model: {
                        value: _vm.currentTab,
                        callback: function ($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab",
                      },
                    },
                    [
                      _c("v-tab", { attrs: { href: "#tab-1" } }, [
                        _vm._v(" Details "),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-2" } }, [
                        _vm._v(" Shipments "),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-3" } }, [
                        _vm._v(" Documents "),
                      ]),
                      _c("v-tab", { attrs: { href: "#tab-4" } }, [
                        _vm._v(" Notes "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: {
                  active: _vm.loadingIcon,
                  color: "amber",
                  indeterminate: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "pt-0",
          staticStyle: { "max-width": "1600px" },
          attrs: { fluid: "" },
        },
        [
          _c(
            "v-tabs-items",
            {
              staticStyle: { background: "none" },
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c(
                "v-tab-item",
                { attrs: { value: "tab-1" } },
                [_c("detail-tab", { attrs: { "location-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-2" } },
                [
                  _c("shipment", {
                    ref: "shipmentTab",
                    attrs: { "location-id": _vm.id },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-3" } },
                [_c("documents", { attrs: { "location-id": _vm.id } })],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-4" } },
                [_c("notes", { attrs: { "location-id": _vm.id } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }